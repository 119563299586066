.dark-theme{
    background: #393939;
    color: #bbbbbb;
}

.dark-theme .border-bottom{
    border-bottom: 1px solid #4d4d4d !important;
}

.dark-theme .card.bg-light{
    background: #2d2d2d !important;
}

.dark-theme .custom.bg-light{
    background: #2d2d2d !important;
    border: 1px solid #4d4d4d !important;
}

.dark-theme .custom.bg-dark{
    background: #2d2d2d !important;
    border: 1px solid #4d4d4d !important;
}

.dark-theme .bg-light.p-3.border.rounded-sm{
    background: #2d2d2d !important;
    color:#bbbbbb !important;
    border: 1px solid #4d4d4d !important;
}

.dark-theme .progressbar li.active{
    color:#bbbbbb !important;
}

.dark-theme .related-products{
    background: #2d2d2d !important;
    border: 1px solid #4d4d4d !important;
}
.dark-theme .related-products .product-image{
    background-color: #393939 !important;
    border: 1px solid #4d4d4d !important;
}

.dark-theme .border-bottom-dotted {
    border-bottom:1px dashed #3d3d3d;
}

