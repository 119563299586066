:root {
    --mainWhite: #fff;
    --mainWhiteAlpha9: rgba(255, 255, 255, .9);
    --mainBlack: #000;
    --grayBackground: #f8f9fa;
    --colorManufacture: #91479c;
    --colorDevelopment: #fe651e;
    --colorCustomer: #4398e8;
    --colorRepair: #d39b2c;
    --colorRecycle: #049e48;
    --colorCustom: red;
    --customCardNameColor0: #91479c;
    --customCardNameColor1: #fe651e;
    --customCardNameColor2: #4398e8;
    --customCardNameColor3: #d39b2c;
    --customCardNameColor4: #049e48;
    --colorSelfBoot: #090;
    --colorBehavioral: #8100a1;
    --colorAdvTesting: #00b3b3;
    --colorProductSupport: #668cff;
    --colorReportLogs: #f66;
    --colorBurnIn: #c40;
    --colorRapid: #fa0
}


body, #main-wrapper {
    min-height: 100vh;
}

.flex-fill {
    flex: 1 1 auto;
}

body {
    position: relative;
    font-family: 'Oxygen', sans-serif;
    background: var(--mainWhite);
    color: var(--mainBlack);
}

div#main-wrapper {

}

@media only screen and (min-width: 1200px) {
    .container {
        max-width: 1550px !important;
    }
}

.btn:focus, .btn:active {
    outline: none !important;
    box-shadow: none !important;
}


.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-self: flex-start
}

.thumb-horizontal{
    background: rgba(0,0,0,0.2);
    border-radius:3px;
}

.thumb-vertical{
    background: rgba(0,0,0,0.2);
    border-radius:3px;
}


/*################################*/
/*#########[ DARK THEME ]#########*/
/*###############################*/


.dark-theme .thumb-horizontal{
    background: rgba(255,255,255,0.2);
}

.dark-theme .thumb-vertical{
    background: rgba(255,255,255,0.2);
}


/*REDUX TOASTR style overrides*/
.redux-toastr .top-right .rrt-holder,
.redux-toastr .top-right svg{
    width:20px !important;
    height:20px !important;
    left:11px !important;
}

.rrt-middle-container{
    width:78% !important;
    left:-40px;
}

.redux-toastr .top-right{
  font-size:13px;
    word-break: break-word;
    white-space: pre-wrap;
    -moz-white-space: pre-wrap;
}